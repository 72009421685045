import pick from 'ramda/src/pick';

import { utmQueryStringKeys } from '../../constants';

/**
 * Returns a key/value pairing of UTMs. If any query params are provided in the
 * query string already, it will override any key/value pairings that may exist in
 * defaultUtms.
 *
 * @param {Record<string, unknown>} query
 * @param {Record<string, unknown>} defaultUtms
 */
export const getUtms = (
  query: Record<string, unknown>,
  defaultUtms: Record<string, unknown>,
) => {
  const utmQueryParams = pick(utmQueryStringKeys, query);
  return {
    ...defaultUtms,
    ...utmQueryParams,
  };
};
