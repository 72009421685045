import React from 'react';
import styled, { keyframes } from 'styled-components';
import LoadingBone from './assets/loading-bone';
import { Flex } from '../../next-components/Flex';
import { Box } from '../../next-components/box';
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const Spin = styled(Box)`
  transition: transform 1500ms ease-in-out;
  animation: ${spin} 0.7s ease-in-out infinite;
`;
const LoadingBoneSpinner = () => <Flex alignSelf="center" bg="rgba(0, 0, 0, 0.8)" borderRadius="15px" p="15px" px="20px" data-sentry-element="Flex" data-sentry-component="LoadingBoneSpinner" data-sentry-source-file="index.tsx">
    <Spin data-sentry-element="Spin" data-sentry-source-file="index.tsx">
      <LoadingBone height="60px" width="60px" data-sentry-element="LoadingBone" data-sentry-source-file="index.tsx" />
    </Spin>
  </Flex>;
export default LoadingBoneSpinner;