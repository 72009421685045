import { pick } from 'ramda';
import { camelizeKeys } from 'humps';
import { useGetURLQuery } from './useGetURLQuery';
import { QueryStringKeys } from '../constants';
import { getUtms } from '../utils/getUtms';
import { Attribution } from '../types/api';

export const useGetAttributionFromQuery = () => {
  const query = useGetURLQuery();

  const nonUtmAttributions = pick([
    QueryStringKeys.AdCreative,
    QueryStringKeys.AdGroup,
    QueryStringKeys.ADID,
    QueryStringKeys.GCLID,
  ], query);

  const execute = () => {
    if (!query) {
      return null;
    }
    const utmAttribution = getUtms(query, nonUtmAttributions);
    return camelizeKeys(utmAttribution) as unknown as Attribution;
  };

  return {
    execute,
  };
};
