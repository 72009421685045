import React, { forwardRef } from 'react';
import PhoneInput from 'react-phone-number-input';
import { PhoneTextFieldGlobalStyle } from './PhoneTextFieldGlobalStyle';
import { Typography, TypographyProps } from '../Typography';
import { TextField, TextFieldProps } from '../../wag-react/core-components/textfield';
import { Box } from '../../wag-react/next-components/box';
type E164Number = string;
type Props = Omit<React.ComponentProps<typeof PhoneInput>, 'error'> & Omit<TextFieldProps, 'ref' | 'onChange'> & {
  helperText?: string;
  helperTextProps?: TypographyProps;
  error?: boolean;
  value?: string | E164Number;
};
type PhoneTextFieldRef = Pick<TextFieldProps, 'ref'>;
const PhoneInputComponent = forwardRef<PhoneTextFieldRef, TextFieldProps>(({
  inputProps,
  ...rest
}, ref) => <TextField variant="filled" {...rest} inputProps={{
  fontFamily: 'muli',
  ...inputProps
}} ref={ref} />);
PhoneInputComponent.displayName = 'PhoneInputComponent';
export const PhoneTextField = ({
  helperText,
  helperTextProps,
  error,
  onChange,
  value,
  ...rest
}: Props) => <Box data-sentry-element="Box" data-sentry-component="PhoneTextField" data-sentry-source-file="index.tsx">
    <PhoneTextFieldGlobalStyle error={error} data-sentry-element="PhoneTextFieldGlobalStyle" data-sentry-source-file="index.tsx" />
    <PhoneInput international={false} inputComponent={PhoneInputComponent} defaultCountry="US" countrySelectProps={{
    disabled: true
  }} onChange={onChange} value={value} {...rest} data-sentry-element="PhoneInput" data-sentry-source-file="index.tsx" />
    {helperText && <Typography variant="legal" color={error ? 'red' : 'initial'} {...helperTextProps}>
        {helperText}
      </Typography>}
  </Box>;
export type PhoneTextFieldProps = Props;