import * as Yup from 'yup';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export const phoneSchema = Yup
  .string()
  .required()
  .test('phone-test', 'Please enter a valid phone number', (value: string) => {
    if (!value) return false;
    // Ensure value is a string and not empty
    if (typeof value !== 'string') return false;
    try {
      return isPossiblePhoneNumber(value);
    } catch (error) {
      return false;
    }
  });
